import styled from '@emotion/styled'
import React from 'react'

const Dots = () => {
  return (
    <Root>
        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>

        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>
        
        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>

        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>

        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>

        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>

        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>
        <Dot></Dot>

    </Root>
  )
}

const Root = styled("div")({
    position: "absolute",
    top: "0",
    left: "0",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    width: "130px",
})

const Dot = styled("div")({
    height: "2px",
    width: "2px",
    backgroundColor: "#C4C4C4",
    borderRadius: "50%",
    marginRight: "20px",
    marginBottom: "30px",
    display:"flex",
    flexDirection: "row"
})

// const LastDots = styled("div")({
//   height: "2px",
//     width: "2px",
//     backgroundColor: "#C4C4C4",
//     borderRadius: "50%",
//     marginRight: "20px",
//     display:"flex",
//     flexDirection: "row"
// })

export default Dots