import developer from "../../assets/images/vuesax/linear/code-circle.png"
import copywriter from "../../assets/images/vuesax/linear/book.png"
import devops from "../../assets/images/vuesax/outline/data.png"
import ux from "../../assets/images/vuesax/outline/note-2.png"
import designer from "../../assets/images/vuesax/outline/pen-tool-2.png"
import socialMedia from "../../assets/images/vuesax/outline/people.png"
import historian from "../../assets/images/vuesax/outline/la_globe-americas.png"
import translator from "../../assets/images/vuesax/outline/heroicons-outline_translate.png"

const Jobs = [
    {
        id: 1,
        title: "Developer",
        imgUrl: developer
    },
    {        
        id: 2,
        title: "Copywriter",
        imgUrl: copywriter
    },
    {
        id: 3,
        title: "Social Media",
        imgUrl: socialMedia
    },
    {
        id: 4,
        title: "DevOps Engineer",
        imgUrl: devops
    },
    {
        id: 5,
        title: "Designer",
        imgUrl: designer
    },
    {
        id: 6,
        title: "Translator",
        imgUrl: translator
    },
    {
        id: 7,
        title: "Historian",
        imgUrl: historian
    },
    {
        id: 8,
        title: "UX Designer",
        imgUrl: ux
    },
]

export default Jobs