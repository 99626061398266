import styled from '@emotion/styled';
import { Button } from '@mui/material';
import React from 'react'

const Buttons = ({name, onClick, style}) => {
  return (
    <GetStartedButton style={style} onClick={onClick}>
      {name}
    </GetStartedButton>
  )
}

const GetStartedButton = styled(Button)({
  background: "linear-gradient(116.31deg, #7F00FF 14.02%, #0CEBEB 119.07%)",
  borderRadius: "10px",
  padding: "12px 28px",
  gap: "10px",
  width: "400px",
  height: "58px",
  marginTop: "40px",
  color: "white",
  fontFamily: "Be Vietnam Pro, sans-serif",
  fontWeight: "700",
  fontSize: "24px",
  lineHeight: "34px",
  textTransform: "none",
  "@media (max-width: 1440px)": {
    width: "250px",
    height: "48px",
    fontSize: "18px"
  },
  "@media (max-width: 600px)": {
    width: "330px",
  },
});

export default Buttons