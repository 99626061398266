import React, { useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Button,
  // Icon,
} from "@mui/material";
import styled from "@emotion/styled";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
// import buttonPic from "../../assets/images/header-article-box-active.png";
import Buttons from "../Buttons/Buttons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import facebook from "../../assets/images/Facebook_white.png";
import google from "../../assets/images/Google_white.png";
import twitter from "../../assets/images/twitter3.png";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import linkedin from "../../assets/images/linkedin3.png";
import { useDispatch } from "react-redux";
import { setToken, setProvider } from "../../features/TokenSlice";

const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [input, setInput] = useState({
    email: "",
    password: "",
    showPassword: false,
  });

  const emailHandleChange = () => {
    // eslint-disable-next-line
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!regex.test(input.email)) {
      setError("Please enter your email");
      setTimeout(() => {
        setError("");
      }, 2500);
    } else {
      signInHandler();
    }
  };

  const facebookOpen = () => {
    window.open(
      `${process.env.REACT_APP_API_URL}/api/connect/facebook`,
      "_self"
    );
    dispatch(setProvider("facebook"));
  };

  const googleOpen = () => {
    window.open(`${process.env.REACT_APP_API_URL}/api/connect/google`, "_self");
    dispatch(setProvider("google"));
  };

  const linkedinOpen = () => {
    window.open(
      `${process.env.REACT_APP_API_URL}/api/connect/linkedin`,
      "_self"
    );
    dispatch(setProvider("linkedin"));
  };

  const twitterOpen = () => {
    window.open(
      `${process.env.REACT_APP_API_URL}/api/connect/twitter`,
      "_self"
    );
    dispatch(setProvider("twitter"));
  };

  async function signInHandler() {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/auth/local`, {
        identifier: input.email,
        password: input.password,
      })
      .then((res) => {
        setSuccess("Login successful. Redirecting to Dashboard...");
        dispatch(setToken(res.data.jwt));
        setTimeout(() => {
          navigate("/dashboard");
        }, 2500);
      })
      .catch((err) => {
        setError("Could not Sign in, please try again");
        setTimeout(() => {
          setError("");
        }, 2500);
      });
  }

  const handleClickShowPassword = () => {
    setInput({
      ...input,
      showPassword: !input.showPassword,
    });
  };

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {error && (
        <ErrorContainer>
          <ErrorOutlineIcon color="error" fontSize="large" />
          <ErrorMessage children={error} />
        </ErrorContainer>
      )}

      {success && (
        <SuccessContainer>
          <CheckCircleOutlineIcon
            color="success"
            fontSize="large"
            style={{ marginRight: "30px" }}
          />
          <SuccesMessage children={success} />
        </SuccessContainer>
      )}
      <Root>
        <SocialSignInContainer>
          <Typography
            variant="h5"
            style={{
              textAlign: "center",
              color: "white",
              fontSize: "20px",
              fontWeight: "700",
              marginBottom: "20px",
              fontFamily: "Be Vietnam Pro",
            }}
          >
            Sign in with:{" "}
          </Typography>
          <SocialSigninIcons>
            <SocialSignIn onClick={facebookOpen}>
              <img
                style={{ width: "40px", height: "40px", marginRight: "10px" }}
                alt="facebook_png_signin"
                src={facebook}
              />
              <Name>Facebook</Name>
            </SocialSignIn>
            <SocialSignIn onClick={googleOpen}>
              <img
                alt="google_png_signin"
                src={google}
                style={{ width: "40px", height: "40px", marginRight: "10px" }}
              />
              <Name>Google</Name>
            </SocialSignIn>
            <SocialSignIn onClick={linkedinOpen}>
              <img
                alt="linkedin_png_signin"
                src={linkedin}
                style={{ width: "40px", height: "40px", marginRight: "10px" }}
              />
              <Name>Linked In</Name>
            </SocialSignIn>
            <SocialSignIn onClick={twitterOpen}>
              <img
                alt="twitter_png_signin"
                src={twitter}
                style={{ width: "40px", height: "40px", marginRight: "10px" }}
              />
              <Name>Twitter</Name>
            </SocialSignIn>
          </SocialSigninIcons>
        </SocialSignInContainer>
        <Input
          variant="outlined"
          type="email"
          value={input.email}
          label="Email"
          name="email"
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <EmailIcon style={{ color: "white" }} />
              </InputAdornment>
            ),
            style: {
              color: "white",
            },
          }}
        />
        <Input
          variant="outlined"
          type={input.showPassword ? "text" : "password"}
          value={input.password}
          label="Password"
          name="password"
          onChange={onChange}
          InputProps={{
            // <-- This is where the toggle button is added.
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  style={{ color: "white" }}
                >
                  {input.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            style: {
              color: "white",
            },
          }}
        />
        <Buttons name="Sign In" onClick={emailHandleChange} />
        <Typography
          variant="h5"
          style={{
            color: "#9e9e9e",
            fontSize: "20px",
            fontWeight: "700",
            marginTop: "20px",
          }}
        >
          Don't have an account?{" "}
          <span
            style={{ color: "#54FEAA", cursor: "pointer" }}
            onClick={() => navigate("/signup")}
          >
            Sign Up
          </span>
        </Typography>
      </Root>
    </>
  );
};

const Root = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  height: "500px",
  width: "600px",
  position: "absolute",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#0F1B40",
  boxShadow: "104px 50px 40px rgba(0, 0, 0, 0.3)",
  border: "1px solid transparent",
  borderImage:
    "linear-gradient(40deg, #000000 10%, transparent -40%, #ffffff 40%, transparent)",
  borderImageSlice: "1",
  "@media (max-width: 600px)": {
    width: "350px",
  },
});

const Input = styled(TextField)`
  & label.Mui-focused {
    color: white;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: white;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & .MuiInputLabel-root {
    color: white;
  }
  margin-top: 10px;
  width: 400px;
  @media screen and (max-width: 600px) {
    width: 330px;
  }
`;

const SocialSignInContainer = styled("div")({
  width: "400px",
  marginBottom: "30px",
  textAlign: "center",
  "@media (max-width: 600px)": {
    width: "230px",
    textAlign: "center",
  },
});

const SocialSigninIcons = styled("div")({
  display: "grid",
  gridTemplateColumns: "2fr 2fr",
  "@media (max-width: 600px)": {
    display: "flex",
  },
});

const Name = styled(Typography)({
  "@media (max-width: 600px)": {
    display: "none",
  },
});

const SocialSignIn = styled(Button)({
  textTransform: "none",
  border: "none",
  lineHeight: 1.5,
  borderColor: "white",
  color: "white",
});

const ErrorMessage = styled(Typography)({
  color: "#ff0033",
  width: "400px",
  fontWeight: "500",
  fontSize: "18px",
  fontFamily: "Be Vietnam Pro, sans-serif",
  textAlign: "center",
});

const ErrorContainer = styled("div")({
  border: "1px solid #ff0033",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "400px",
  padding: "15px",
  position: "absolute",
  top: "15%",
  left: "50%",
  transform: "translate(-50%, -15%)",
});

const SuccesMessage = styled(Typography)({
  color: "#4BB543",
  width: "400px",
  fontWeight: "500",
  fontSize: "14px",
  fontFamily: "Be Vietnam Pro, sans-serif",
});

const SuccessContainer = styled("div")({
  border: "1px solid #4BB543",
  borderRadius: "10px",
  margin: "20px 0",
  display: "flex",
  alignItems: "center",
  width: "400px",
  padding: "15px",
  position: "absolute",
  top: "15%",
  left: "50%",
  transform: "translate(-50%, -15%)",
});

export default Signin;
