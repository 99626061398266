import React, { useState, useEffect } from "react";
import { Button, IconButton, Typography } from "@mui/material";
import styled from "@emotion/styled";
import buttonPic from "../../assets/images/header-article-box-active.png";
import fb from "../../assets/images/Facebook.png";
import linkedin from "../../assets/images/LinkedIn.png";
import twitter from "../../assets/images/Twitter.png";
import tiktok from "../../assets/images/TikTok.png";
import "./Dashboard.scss";
import Dots from "../Dots/Dots";
import discord from "../../assets/images/discord.png";
import Jobs from "../Jobs/Jobs";
import Buttons from "../Buttons/Buttons";
import NavBar from "../DashboardNavBar/NavBar";
import axios from "axios";
import Lottie from "react-lottie-player";
import loader from "../../assets/animations/loader.json";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUserData } from "../../features/TokenSlice";
import { motion } from "framer-motion";

const Dashboard = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token.token);
  const userData = useSelector((state) => state.token.userData);
  const provider = useSelector((state) => state.token.provider);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const sweepWidget = `https://sweepwidget.com/view/41103-1b2wkuyo?data-name=${userData.username}&data-email=${userData.email}&data-auto-login=1`;

  const discordServer = () => {
    window.open("https://discord.gg/aUPk6ZvSQC", "_blank");
  };

  const sendEmail = () => {
    window.location =
      "mailto:izbori@4bih.org?subject=Application Confirmation for elections&body";
  };

  useEffect(() => {
    if (token) {
      getUser();
    }
    // eslint-disable-next-line
  }, [token]);

  const getUser = async () => {
    await axios
      .get(`${process.env.REACT_APP_API_URL}/api/users/me/`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch(setUserData(res.data));
        setLoading(true);
      });
  };

  useEffect(() => {
    providerData();
    // eslint-disable-next-line
  }, []);

  var urlGoogle = `http://dashboard.4bih.org/#id_token=${location.search}`;
  var firstHalfGoogle = urlGoogle.split("#id_token=")[1];

  async function providerData() {
    await axios
      .get(
        `${process.env.REACT_APP_API_URL}/api/auth/${provider}/callback${firstHalfGoogle}`
      )
      .then((res) => {
        dispatch(setToken(res.data.jwt));
      })
      .catch((err) => console.log(err));
  }

 
  const ApplyHandler = () => {
    window.open("https://utlojgtc71q.typeform.com/to/WytpYk7C", "_blank");
  };

  const guideLink = () => {
    window.open("https://youtu.be/U5_y90kgo7w", "_blank");
  };

  return (
    <>
      {loading ? (
    <>
      <div>
        <NavBar
          userData={userData}
          setShow={setShow}
        />
      </div>
      {show && (
        <iframe src={sweepWidget} title="sweepwidget" className="sweepwidget" />
      )}
      <Root>
        <motion.div
          className="campaign_left_div"
          animate={{ x: [-300, 0] }}
          transition={{ duration: 1, ease: "easeOut" }}
        >
          <img
            src={buttonPic}
            alt="buttonPic"
            style={{ width: "100%", height: "17%" }}
          />
          <CampaignName>CAMPAIGN 1</CampaignName>
          <CampaignHeaderText>Spread Awareness</CampaignHeaderText>
          <SubmitEmailText variant="h5">Share the Message on:</SubmitEmailText>
          <SocialIcons>
            <OneSocialIcon>
              <img src={fb} alt="fb__icon" />
            </OneSocialIcon>
            <OneSocialIcon>
              <img src={linkedin} alt="fb__icon" />
            </OneSocialIcon>
            <OneSocialIcon>
              <img src={twitter} alt="fb__icon" />
            </OneSocialIcon>
            <OneSocialIcon>
              <img src={tiktok} alt="fb__icon" />
            </OneSocialIcon>
          </SocialIcons>
          <Reward>Earn 200 BiH</Reward>
          <Buttons
            name="Get Started"
            style={{ marginTop: "35px" }}
            onClick={() => setShow(true)}
          />
        </motion.div>
        <motion.div
          className="campaign_right_div"
          animate={{ x: [300, 0] }}
          transition={{ duration: 1.25, ease: "easeOut" }}
        >
          <img
            src={buttonPic}
            alt="buttonPic"
            style={{ width: "100%", height: "17%" }}
          />
          <CampaignName>CAMPAIGN 2</CampaignName>
          <CampaignHeaderText>Register to Vote</CampaignHeaderText>
          <SubmitEmailText variant="h5">
            Follow The Guide Here:{" "}
            <span
              style={{ color: "#54FEAA", cursor: "pointer" }}
              onClick={guideLink}
            >
              Guide
            </span>
          </SubmitEmailText>
          <SubmitEmailText variant="h5">
            Submit your confirmation email to:{" "}
            <span style={{ color: "#54FEAA" }}>izbori@4bih.org</span>
          </SubmitEmailText>
          <Reward>Earn 5000 BiH</Reward>
          <Buttons
            name="Get Started"
            style={{ marginTop: "32px" }}
            onClick={sendEmail}
          />
        </motion.div>
        <motion.div
          className="work_for_bih"
          animate={{ x: [-300, 0] }}
          transition={{ duration: 1.50, ease: "easeOut" }}
        >
          <Jobtitle variant="h3">Become part of 4BiH</Jobtitle>
          <JobDescription variant="h5">
            Apply to be part of a working unit and earn more BiH Tokens!
          </JobDescription>
          <Dots />
          <JobsList>
            {Jobs.map((job) => (
              <JobsContainer key={job.id}>
                <JobsImages src={job.imgUrl} alt="jobs__list" />
                <JobsTitle>{job.title}</JobsTitle>
              </JobsContainer>
            ))}
          </JobsList>
          <Buttons
            name="Apply Here"
            style={{ width: "100%", marginTop: "30px" }}
            onClick={ApplyHandler}
          />
        </motion.div>
        <Discord
          onClick={discordServer}
          component={motion.button}
          animate={{ x: [300, 0] }}
          transition={{ duration: 1.75, ease: "easeOut" }}
        >
          <DiscordImg src={discord} alt="discord__logo" />
          <DiscordText variant="h5">join our discord</DiscordText>
        </Discord>
      </Root>
    </>
      ) : (
        <Lottie
          loop
          animationData={loader}
          play
          style={{
            width: 300,
            height: 300,
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: "999",
          }}
        />
      )}
    </>
  );
};

const JobsContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  width: "100%",
  marginBottom: "10px",
  "@media (max-width: 1440px)": {
    marginBottom: "5px",
  },
  "@media (max-width: 1024px)": {
    marginBottom: "0px",
  },
});

const JobsImages = styled("img")({
  width: "30px",
  height: "30px",
  "@media (max-width: 1440px)": {
    width: "20px",
    height: "20px",
  },
});

const DiscordText = styled(Typography)({
  fontWeight: "700",
  fontFamily: "Be Vietnam Pro",
  "@media (max-width: 868px)": {
    fontSize: "16px",
    marginLeft: "20px",
  },
});

const DiscordImg = styled("img")({
  marginBottom: "15px",
  "@media (max-width: 868px)": {
    marginBottom: "0",
  },
});

const JobsTitle = styled("h5")({
  fontSize: "20px",
  fontWeight: "500",
  color: "white",
  lineHeight: "25px",
  marginLeft: "20px",
  fontFamily: "Be Vietnam Pro",
  "@media (max-width: 1440px)": {
    fontSize: "14px",
  },
  "@media (max-width: 1024px)": {
    fontSize: "12px",
    marginTop: "0",
  },
});

const CampaignHeaderText = styled(Typography)({
  color: "white",
  fontFamily: "Be Vietnam Pro",
  fontSize: "24px",
  fontWeight: "400",
  lineHeight: "30px",
  marginTop: "20px",
  "@media (max-width: 1440px)": {
    fontSize: "20px",
    marginTop: "15px",
  },
});

const SubmitEmailText = styled(Typography)({
  color: "#9e9e9e",
  fontFamily: "Be Vietnam Pro",
  fontSize: "20px",
  fontWeight: "700",
  marginTop: "20px",
  "@media (max-width: 1440px)": {
    fontSize: "16px",
    fontWeight: "600",
    marginTop: "15px",
  },
  "@media (max-width: 1024px)": {
    fontSize: "14px",
    fontWeight: "600",
    marginTop: "15px",
  },
});

const JobsList = styled("div")({
  display: "grid",
  gridTemplateColumns: "repeat(4, 1fr)",
  "@media (max-width: 600px)": {
    gridTemplateColumns: "repeat(2, 2fr)",
  },
});

const Root = styled("div")({
  width: "1300px",
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  margin: "auto",
  "@media (max-width: 1440px)": {
    width: "1000px",
  },
  "@media (max-width: 1024px)": {
    width: "800px",
  },
  "@media (max-width: 868px)": {
    width: "750px",
  },
  "@media (max-width: 800px)": {
    width: "550px",
  },
  "@media (max-width: 600px)": {
    width: "400px",
  },
  "@media (max-width: 400px)": {
    width: "350px",
  },
});

const CampaignName = styled(Typography)({
  position: "absolute",
  top: "7%",
  left: "40%",
  color: "white",
  fontWeight: "700",
  fontSize: "20px",
  fontFamily: "Be Vietnam Pro",
  "@media (max-width: 1440px)": {
    left: "35%",
  },
  "@media (max-width: 1024px)": {
    left: "37%",
    fontSize: "15px",
    top: "8%",
  },
  "@media (max-width: 800px)": {
    left: "41%",
    fontSize: "15px",
    top: "8%",
  },
});

const SocialIcons = styled("div")({
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  marginTop: "10px",
});

const OneSocialIcon = styled(IconButton)({
  background: "black",
  marginLeft: "10px",
});

const Reward = styled("div")({
  background: "#7f00ff",
  boxShadow: "0px 4px 30px #7f00ff",
  borderRadius: "100px",
  width: "155px",
  height: "36px",
  padding: "10px",
  gap: "10px",
  fontFamily: "Be Vietnam Pro",
  fontWeight: "700",
  fontSize: "16px",
  lineHeight: "24px",
  textAlign: "center",
  textTransform: "uppercase",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "auto",
  color: "white",
  marginTop: "20px",
});

const Discord = styled(Button)`
  display: flex;
  flex-direction: column;
  width: 170px;
  height: 310px;
  margin-top: 40px;
  margin-bottom: 20px;
  box-shadow: none;
  font-family: Be Vietnam Pro;
  text-transform: none;
  background: linear-gradient(#010206, #010206) padding-box,
    linear-gradient(100deg, #7f00ff, #0cebeb) border-box;
  border: 1px solid transparent;
  border-radius: 10px;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  @media screen and (max-width: 1440px) {
    height: 255px;
  }
  @media screen and (max-width: 868px) {
    height: 48px;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
`;

const Jobtitle = styled(Typography)({
  color: "white",
  fontFamily: "Be Vietnam Pro",
  fontSize: "32px",
  fontWeight: "400",
  fontStyle: "normal",
  lineHeight: "40px",

  "@media (max-width: 1440px)": {
    fontSize: "24px",
  },
});

const JobDescription = styled(Typography)({
  color: "white",
  marginBottom: "30px",
  fontFamily: "Be Vietnam Pro",
  fontSize: "20px",
  fontWeight: "700",
  lineHeight: "25px",
  "@media (max-width: 1440px)": {
    fontSize: "18px",
    marginBottom: "20px",
  },
  "@media (max-width: 1024px)": {
    marginBottom: "10px",
  },
  "@media (max-width: 868px)": {
    marginBottom: "30px",
    fontWeight: "600",
    fontSize: "16px",
  },
  "@media (max-width: 800px)": {
    marginBottom: "0px",
  },
});

export default Dashboard;
