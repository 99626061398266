import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import React from "react";
import Buttons from "../Buttons/Buttons";
import { useNavigate } from "react-router-dom";

const NotFoundPage = () => {
  const navigate = useNavigate();
  return (
    <Root>
      <CampaignHeaderText>404 Page Not Found</CampaignHeaderText>
      <Buttons name="Go Home" onClick={() => navigate("/")} />
    </Root>
  );
};

const Root = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  padding: "10px",
  border: "1px solid transparent",
  borderImage:
    "linear-gradient(40deg, #000000 10%, transparent -40%, #ffffff 40%, transparent)",
  borderImageSlice: "1",
  width: "600px",
  height: "420px",
  background: "#152861",
  boxShadow: "104px 50px 40px rgba(0, 0, 0, 0.3)",
  "@media (max-width: 1440px)": {
    width: "470px",
    height: "350px",
    marginRight: "0",
  },
  "@media (max-width: 1024px)": {
    width: "370px",
    height: "350px",
    marginRight: "0",
  },
  "@media (max-width: 800px)": {
    width: "550px",
    height: "350px",
    margin: "30px auto",
  },
});

const CampaignHeaderText = styled(Typography)({
  color: "white",
  fontFamily: "Be Vietnam Pro, sans-serif",
  fontSize: "24px",
  fontWeight: "400",
  marginTop: "20px",
  "@media (max-width: 1440px)": {
    fontSize: "20px",
    marginTop: "15px",
  },
});

export default NotFoundPage;
