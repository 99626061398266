import styled from "@emotion/styled";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import fbLogo from "../../assets/images/Facebook_white.png";
import googleLogo from "../../assets/images/Google_white.png";
import gmailLogo from "../../assets/images/Gmail_white.png";
import arrowRight from "../../assets/images/arrow-right.png";
import linkedinLogo from "../../assets/images/linkedin2.png";
// import twitterLogo from "../../assets/images/twitter2.png";
import { useDispatch } from "react-redux";
import { setProvider } from "../../features/TokenSlice";

const PreSignin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const facebookOpen = () => {
    window.open(
      `${process.env.REACT_APP_API_URL}/api/connect/facebook`,
      "_self"
    );
    dispatch(setProvider("facebook"));
  };

  const googleOpen = () => {
    window.open(`${process.env.REACT_APP_API_URL}/api/connect/google`, "_self");
    dispatch(setProvider("google"));
  };

  const LinkedninOpen = () => {
    window.open(
      `${process.env.REACT_APP_API_URL}/api/connect/linkedin`,
      "_self"
    );
    dispatch(setProvider("linkedin"));
  };

  // const TwitterOpen = () => {
  //   window.open(
  //     `${process.env.REACT_APP_API_URL}/api/connect/twitter`,
  //     "_self"
  //   );
  //   dispatch(setProvider("twitter"));
  // };

  const SignupOpen = () => {
    navigate("/signup");
  };

  return (
    <Root>
      <Title variant="h5">Sign in with: </Title>
      <Buttons onClick={facebookOpen}>
        <Logo src={fbLogo} alt="fb_logo_presignin" />
        <ButtonName>Facebook</ButtonName>
        <ArrowRight src={arrowRight} alt="arrow_right_presignin" />
      </Buttons>
      <Buttons onClick={googleOpen}>
        <Logo src={googleLogo} alt="fb_logo_presignin" />
        <ButtonName>Google</ButtonName>
        <ArrowRight src={arrowRight} alt="arrow_right_presignin" />
      </Buttons>
      <Buttons onClick={() => navigate("/email")}>
        <Logo src={gmailLogo} alt="fb_logo_presignin" />
        <ButtonName>Email</ButtonName>
        <ArrowRight src={arrowRight} alt="arrow_right_presignin" />
      </Buttons>
      <Buttons onClick={LinkedninOpen}>
        <Logo src={linkedinLogo} alt="fb_logo_presignin" />
        <ButtonName>LinkedIn</ButtonName>
        <ArrowRight src={arrowRight} alt="arrow_right_presignin" />
      </Buttons>
      {/* <Buttons onClick={TwitterOpen}>
        <Logo src={twitterLogo} alt="fb_logo_presignin" />
        <ButtonName>Twitter</ButtonName>
        <ArrowRight src={arrowRight} alt="arrow_right_presignin" />
      </Buttons> */}
      <DividerText>OR</DividerText>
      <SignoutButton onClick={SignupOpen}>
        <ButtonName>Sign Up</ButtonName>
        <ArrowRight src={arrowRight} alt="arrow_right_presignin" />
      </SignoutButton>
    </Root>
  );
};

const SignoutButton = styled(Button)`
  background: linear-gradient(#050916, #050916) padding-box,
    linear-gradient(45deg, #7f00ff, #0cebeb) border-box;
  border: 2px solid transparent;
  border-radius: 10px;
  height: 65px;
  position: relative;
  width: 85%;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  font-family: Be Vietnam Pro;
  text-transform: none;
`;

const DividerText = styled(Typography)({
  color: "white",
  fontFamily: "Be Vietnam Pro",
  fontSize: "16px",
  marginBottom: "20px",
});

const Buttons = styled(Button)({
  position: "relative",
  width: "85%",
  height: "65px",
  background: "#050916",
  color: "white",
  display: "flex",
  justifyContent: "start",
  padding: "24px 24px",
  borderRadius: "10px",
  marginBottom: "20px",
  "&:hover": {
    background: "#0a1126",
  },
});

const ArrowRight = styled("img")({
  position: "absolute",
  right: "24px",
});

const Logo = styled("img")({
  width: "20px",
  height: "20px",
  marginRight: "20px",

  "@media (max-width: 600px)": {
    width: "20px",
    height: "20px",
  },
});

const ButtonName = styled(Typography)({
  color: "white",
  fontFamily: "Be Vietnam Pro",
  fontWeight: "500",
  fontSize: "14px",
  textTransform: "none",
  "@media (max-width: 600px)": {
    fontSize: "14px",
  },
});

const Title = styled(Typography)({
  color: "white",
  fontSize: "20px",
  fontWeight: "700",
  marginBottom: "20px",
  fontFamily: "Be Vietnam Pro",
  textTransform: "uppercase",
});

const Root = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "650px",
  width: "600px",
  position: "absolute",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#0F1B40",
  border: "1px solid transparent",
  padding: "20px",
  borderImage:
    "linear-gradient(40deg, #000000 10%, transparent -40%, #ffffff 40%, transparent)",
  borderImageSlice: "1",
  "@media (max-width: 600px)": {
    width: "350px",
  },
});

export default PreSignin;
