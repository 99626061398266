import React, { useState } from "react";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
// import Button from "@mui/material/Button";
// import fbLogo from "../../assets/images/fb.svg";
import logo from "../../assets/images/favicon.png";
import styled from "@emotion/styled";
import { Button } from "@mui/material";
import "./NavBar.scss";
import googleLogo from "../../assets/images/Google_white.png";
import facebookLogo from "../../assets/images/Facebook_white.png";
import linkedinLogo from "../../assets/images/linkedin2.png";
import twitterLogo from "../../assets/images/twitter2.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setToken, setUserData } from "../../features/TokenSlice";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { motion } from "framer-motion";

const NavBar = ({ userData, setShow }) => {
  const provider = useSelector((state) => state.token.provider);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState(false);

  const SignoutHandler = () => {
    dispatch(setToken(false));
    dispatch(setUserData(false));
    window.open(`https://4bih.org`, "_self");
  };

  const logoHandler = () => {
    setShow(false);
    navigate("/dashboard");
  };

  return (
    <>
      <NavToolbar>
        <NavLogo src={logo} alt="4bih__logo" onClick={logoHandler} />
        {userData && (
          <Tokens
            variant="h6"
            color="white"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            BiH Tokens: {userData.BiHtoken}
          </Tokens>
        )}
        <div style={{ display: "flex", flexDirection: "column" }}>
          <NavTypography
            variant="h6"
            color="white"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            {userData.username}
          </NavTypography>
          <NavTypography
            variant="h8"
            color="white"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            {userData.email}
          </NavTypography>
        </div>
        {provider === "google" && (
          <SocialLoginDiv>
            <Logo src={googleLogo} alt="google_logo_navbar" />
          </SocialLoginDiv>
        )}
        {provider === "facebook" && (
          <SocialLoginDiv>
            <Logo src={facebookLogo} alt="facebook_logo_nav" />
          </SocialLoginDiv>
        )}
        {provider === "linkedin" && (
          <SocialLoginDiv>
            <Logo src={linkedinLogo} alt="linkedin_logo_nav" />
          </SocialLoginDiv>
        )}
        {provider === "twitter" && (
          <SocialLoginDiv>
            <Logo src={twitterLogo} alt="twitter_logo_nav" />
          </SocialLoginDiv>
        )}
        <SignoutButton
          className="gradient-border-mask"
          onClick={SignoutHandler}
        >
          Signout
        </SignoutButton>
      </NavToolbar>

      <NavBarToolBarMobile>
        <NavLogo src={logo} alt="4bih__logo" onClick={logoHandler} />

        {userData && (
          <Tokens
            variant="h6"
            color="white"
            component="div"
            sx={{ flexGrow: 1 }}
          >
            BiH Tokens: {userData.BiHtoken}
          </Tokens>
        )}

        <MenuIcon
          style={{
            width: "30px",
            height: "30px",
            color: "white",
            cursor: "pointer",
          }}
          onClick={() => setToggle(true)}
        />

        {toggle && (
          <motion.div
            style={{
              position: "fixed",
              top: "0",
              bottom: "0",
              right: "0",
              zIndex: "9999",
              padding: "1rem",
              width: "80%",
              height: "100vh",
              background:
                "linear-gradient(116.31deg, #7F00FF 14.02%, #0CEBEB 119.07%)",
            }}
            whileInView={{ x: [300, 0] }}
            transition={{ duration: 0.85, ease: "easeOut" }}
          >
            <CloseIcon
              style={{
                width: "30px",
                height: "30px",
                color: "black",
                cursor: "pointer",
                marginBottom: "20px",
              }}
              onClick={() => setToggle(false)}
            />
            <motion.div
              style={{ display: "flex", flexDirection: "column" }}
              whileInView={{ x: [300, 0] }}
              transition={{ duration: 0.85, ease: "easeOut" }}
            >
              <NavTypographyMobile>{userData.username}</NavTypographyMobile>
              <NavTypographyMobile>{userData.email}</NavTypographyMobile>
              {provider === "google" && (
                <SocialLoginDiv
                  whileInView={{ x: [300, 0] }}
                  transition={{ duration: 0.85, ease: "easeOut" }}
                >
                  <Logo src={googleLogo} alt="google_logo_navbar" />
                </SocialLoginDiv>
              )}
              {provider === "facebook" && (
                <SocialLoginDiv
                  whileInView={{ x: [300, 0] }}
                  transition={{ duration: 0.85, ease: "easeOut" }}
                >
                  <Logo src={facebookLogo} alt="facebook_logo_nav" />
                </SocialLoginDiv>
              )}
              {provider === "linkedin" && (
                <SocialLoginDiv
                  whileInView={{ x: [300, 0] }}
                  transition={{ duration: 0.85, ease: "easeOut" }}
                >
                  <Logo src={linkedinLogo} alt="linkedin_logo_nav" />
                </SocialLoginDiv>
              )}
              {provider === "twitter" && (
                <SocialLoginDiv
                  whileInView={{ x: [300, 0] }}
                  transition={{ duration: 0.85, ease: "easeOut" }}
                >
                  <Logo src={twitterLogo} alt="twitter_logo_nav" />
                </SocialLoginDiv>
              )}
            </motion.div>
            <SignoutButton
              onClick={SignoutHandler}
              component={motion.div}
              whileInView={{ x: [300, 0] }}
              transition={{ duration: 0.85, ease: "easeOut" }}
            >
              Signout
            </SignoutButton>
          </motion.div>
        )}
      </NavBarToolBarMobile>
    </>
  );
};

const NavBarToolBarMobile = styled(Toolbar)({
  width: "400px",
  paddingTop: "40px",
  margin: "auto",
  paddingBottom: "60px",
  display: "flex",
  justifyContent: "space-between",
  "@media (min-width: 600px)": {
    display: "none",
  },
});

const NavLogo = styled("img")({
  width: "50px",
  height: "50px",
  marginRight: "20px",
  cursor: "pointer",
  "@media (max-width: 800px)": {
    width: "30px",
    height: "30px",
  },
});

const Tokens = styled(Typography)({
  fontFamily: "Be Vietnam Pro, sans-serif",
  fontWeight: "700",
  "@media (max-width: 800px)": {
    fontSize: "14px",
  },
});

const NavTypographyMobile = styled("h1")({
  fontFamily: "Be Vietnam Pro, sans-serif",
  fontWeight: "700",
  color: "white",
  marginRight: "10px",
  "@media (max-width: 800px)": {
    fontSize: "14px",
  },
});

const NavTypography = styled(Typography)({
  fontFamily: "Be Vietnam Pro, sans-serif",
  fontWeight: "700",
  color: "white",
  "@media (max-width: 800px)": {
    fontSize: "14px",
  },
  "@media (max-width: 600px)": {
    display: "none",
  },
});

const NavToolbar = styled(Toolbar)({
  position: "relative",
  width: "1350px",
  paddingTop: "40px",
  margin: "auto",
  paddingBottom: "60px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  "@media (max-width: 1440px)": {
    width: "1050px",
  },
  "@media (max-width: 1024px)": {
    width: "850px",
  },
  "@media (max-width: 868px)": {
    width: "800px",
  },
  "@media (max-width: 800px)": {
    width: "600px",
  },
  "@media (max-width: 600px)": {
    display: "none",
  },
});

const SignoutButton = styled(Button)`
  background: linear-gradient(#010206, #010206) padding-box,
    linear-gradient(45deg, #7f00ff, #0cebeb) border-box;
  border: 2px solid transparent;
  border-radius: 10px;
  height: 40px;
  margin-left: 20px;
  width: 80px;
  margin-right: 5px;
  padding: 10px 20px;
  color: white;
  cursor: pointer;
  font-family: Be Vietnam Pro;
  text-transform: none;
  @media screen and (max-width: 600px) {
    margin-left: 0;
  }
`;

const SocialLoginDiv = styled("motion.div")({
  color: "white",
  right: "9em",
  marginTop: "5px",
  marginLeft: "10px",
  "@media (max-width: 600px)": {
    marginLeft: "0",
  },
});

const Logo = styled("img")({
  "@media (max-width: 800px)": {
    width: "30px",
    height: "30px",
  },
  "@media (max-width: 600px)": {
    width: "30px",
    height: "30px",
    marginTop: "20px",
  },
});

export default NavBar;
