import Dashboard from "./components/Dashboard/Dashboard";
import "./app.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signin from "./components/Signin/Signin";
import Signup from "./components/Signup/Signup";
import NotFoundPage from "./components/NotFoundPage/NotFoundPage";
import PreSignin from "./components/Signin/PreSignin";
import PreSignup from "./components/Signup/PreSignup";

function App() {
  return (
    <div className="App">
      <div className="line1"></div>
      <div className="line2"></div>
      <div className="line3"></div>
      <div className="line4"></div>
      <div className="line5"></div>
      <div className="horizontal__line"></div>
      <BrowserRouter>
        <Routes>
          <Route path="/" exact element={<PreSignin />} />
          <Route path="/dashboard" exact element={<Dashboard />} />
          <Route path="/signup" element={<PreSignup />} />
          <Route path="/signupemail" element={<Signup />} />
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/signin" element={<PreSignin />} />
          <Route path="/email" element={<Signin />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
