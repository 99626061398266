import { createSlice } from "@reduxjs/toolkit";

const tokenSlice = createSlice({
  name: "token",
  initialState: { token: false, userData: false, provider: false },
  reducers: {
    setToken(state, { payload }) {
      state.token = payload;
    },
    setUserData(state, { payload }) {
      state.userData = payload;
    },
    setProvider(state, { payload }) {
      state.provider = payload;
    },
  },
});

export const { setToken, setUserData, setProvider } = tokenSlice.actions;

export default tokenSlice.reducer;
