import React, { useState } from "react";
import {
  TextField,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Buttons from "../Buttons/Buttons";
import axios from "axios";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useDispatch } from "react-redux";
import { setToken } from "../../features/TokenSlice";

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [input, setInput] = useState({
    username: "",
    email: "",
    password: "",
    showPassword: false,
  });

  const emailHandleChange = () => {
    // eslint-disable-next-line
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (!regex.test(input.email)) {
      setError("Please enter valid email");
      setTimeout(() => {
        setError("");
      }, 2500);
    } else {
      signUpHandler();
    }
  };

  async function signUpHandler() {
    await axios
      .post(`${process.env.REACT_APP_API_URL}/api/auth/local/register`, {
        username: input.username,
        email: input.email,
        password: input.password,
      })
      .then((res) => {
        setSuccess("Account created. Redirecting to Signin....");
        dispatch(setToken(res.data.jwt));
        setTimeout(() => {
          navigate("/dashboard");
          setSuccess("");
        }, 2500);
      })
      .catch((err) => {
        setError("Could not create an account, please try again");
        setTimeout(() => {
          setError("");
        }, 2500);
        console.log(err);
      });
  }

  const handleClickShowPassword = () => {
    setInput({
      ...input,
      showPassword: !input.showPassword,
    });
  };

  const onChange = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      {error && (
        <ErrorContainer>
          <ErrorOutlineIcon color="error" fontSize="large" />
          <ErrorMessage children={error} />
        </ErrorContainer>
      )}

      {success && (
        <SuccessContainer>
          <CheckCircleOutlineIcon
            color="success"
            fontSize="large"
            style={{ marginRight: "30px" }}
          />
          <SuccesMessage children={success} />
        </SuccessContainer>
      )}
      <Root>
        <SocialSignInContainer>
          <Typography
            variant="h5"
            style={{
              textAlign: "center",
              color: "white",
              fontSize: "20px",
              fontWeight: "700",
              marginBottom: "20px",
            }}
          >
            Sign Up with:{" "}
          </Typography>

        </SocialSignInContainer>
        <Input
          variant="outlined"
          type="username"
          value={input.username}
          label="Name and Surname"
          name="username"
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <AccountCircleIcon style={{ color: "white" }} />
              </InputAdornment>
            ),
            style: {
              color: "white",
            },
          }}
        />
        <Input
          variant="outlined"
          type="email"
          value={input.email}
          label="Email"
          name="email"
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="start">
                <EmailIcon style={{ color: "white" }} />
              </InputAdornment>
            ),
            style: {
              color: "white",
            },
          }}
        />
        <Input
          variant="outlined"
          type={input.showPassword ? "text" : "password"}
          value={input.password}
          label="Password"
          name="password"
          onChange={onChange}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  style={{ color: "white" }}
                >
                  {input.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
            style: {
              color: "white",
            },
          }}
        />
        <Buttons name="Sign Up" onClick={emailHandleChange} />
        <Typography
          variant="h5"
          style={{
            color: "#9e9e9e",
            fontSize: "20px",
            fontWeight: "700",
            marginTop: "20px",
          }}
        >
          Already have an account?{" "}
          <span
            style={{ color: "#54FEAA", cursor: "pointer" }}
            onClick={() => navigate("/signin")}
          >
            Sign In
          </span>
        </Typography>
      </Root>
    </>
  );
};

const Root = styled("div")({
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  height: "550px",
  width: "600px",
  position: "absolute",
  top: "55%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  background: "#152861",
  boxShadow: "104px 50px 40px rgba(0, 0, 0, 0.3)",
  border: "1px solid transparent",
  borderImage:
    "linear-gradient(40deg, #000000 10%, transparent -40%, #ffffff 40%, transparent)",
  borderImageSlice: "1",
  "@media (max-width: 600px)": {
    width: "350px",
  },
});

const Input = styled(TextField)`
  & label.Mui-focused {
    color: white;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: white;
    }
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: white;
  }
  & .MuiInputLabel-root {
    color: white;
  }
  margin-top: 10px;
  width: 400px;
  @media screen and (max-width: 600px) {
    width: 330px;
  }
`;

const SocialSignInContainer = styled("div")({
  width: "400px",
  marginBottom: "30px",
  textAlign: "center",
  "@media (max-width: 600px)": {
    width: "230px",
    textAlign: "center",
  },
});


const ErrorMessage = styled(Typography)({
  color: "#ff0033",
  width: "400px",
  fontWeight: "500",
  fontSize: "18px",
  fontFamily: "Be Vietnam Pro, sans-serif",
  textAlign: "center",
});

const SuccesMessage = styled(Typography)({
  color: "#4BB543",
  width: "400px",
  fontWeight: "500",
  fontSize: "14px",
  fontFamily: "Be Vietnam Pro, sans-serif",
});

const ErrorContainer = styled("div")({
  border: "1px solid #ff0033",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "400px",
  padding: "15px",
  position: "absolute",
  top: "15%",
  left: "50%",
  transform: "translate(-50%, -15%)",
});

const SuccessContainer = styled("div")({
  border: "1px solid #4BB543",
  borderRadius: "10px",
  margin: "20px 0",
  display: "flex",
  alignItems: "center",
  width: "400px",
  padding: "15px",
  position: "absolute",
  top: "15%",
  left: "50%",
  transform: "translate(-50%, -15%)",
});

export default Signup;
